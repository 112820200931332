
	/**
	*
	* 		Modal
	*			- The modal component goes here
	*
	**/

	.modal {
		&-video {
			height:100vh !important;
			&-body {
				max-width: 100% !important;
			}
		}
	}