
	/**
	*
	* 		Header
	*			- The header styles will go hehre
	*
	**/
	
	.header {

		&-home {
		    margin-top: -56px;
		    position: relative;
		    height:100vh;
		    min-height:450px;

		    /*
			    @media screen and (max-width:480px){
			    	padding-bottom:50px;
			    	height:70vh;
			    }
	
				@media screen and (max-width:991px){
				    .header-home{
				        padding-top:200px
				    }
				}
				@media screen and (max-width:480px){
				    .header-home{
				        padding-top:160px;
				        padding-bottom:50px
				    }
				}

		    */

		    .container-np {
		    	height:100%;
		    	max-width:1370px;
		    }

		    &__wrp {
			    width: 100%;
			    max-width: 862px;
			    margin:auto;
			    display: -webkit-box;
			    display: -ms-flexbox;
			    display: flex;
			    -webkit-box-orient: vertical;
			    -webkit-box-direction: normal;
			    -ms-flex-direction: column;
			    flex-direction: column;
			    padding-top:150px;

			    &-col {
				    margin-top: 23px;
				    display: -webkit-box;
				    display: -ms-flexbox;
				    display: flex;
				    width: 100%;

				    @media screen and (max-width:767px){
				        -webkit-box-orient: vertical;
				        -webkit-box-direction: reverse;
				        -ms-flex-direction: column-reverse;
				        flex-direction: column-reverse;
				    }

				    @media screen and (max-width:480px){
				    	margin-top:0;
				    }
			    }
		    }

		    &__bg {
		    	&-video {
				    width: 125%;
				    position: absolute;
				    top: -3px;
				    left: 0;
				    z-index: -2;

		    		&-wrp {
					    top:0;
					    left:0;
					    width:100%;
					    height:100%;
					    z-index:-1;
					    position:absolute;
					    pointer-events:none;
					    overflow:hidden;

					    iframe {
					    	width: 100vw;
							height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
							min-height: 100vh;
							min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
					    }

					    .overlay {
						    display: -webkit-box;
						    display: -ms-flexbox;
						    display: flex;
						    width: 100%;
						    height: 100%;
						    background: black;
						    opacity: 0.5;
					    }

		    		}
		    	}
		    }

		    &__title {
			    font-size:73px;
			    line-height:0.9;
			    letter-spacing:-1.46px;
			    font-family:map-get($fonts,'montreal');
			    color:#fff;
			    margin-bottom:52px;
			    text-align:center;

			    .font2 {
				    font-family:map-get($fonts,'beatrice');
				    font-style:italic;
				    font-size:70px;
			    }

			    @media screen and (max-width:767px){
			        font-size:65px;
			        margin-bottom:100px;

			        .font2 {
			        	font-size:65px;
			        }
			    }

			    @media screen and (max-width:480px){
			        font-size:48px;
			        line-height:1.1;

			        .font2 {
				        font-size:48px;
				        line-height:1.1;
			        }
			    }

			    &-2 {
				    font-family:map-get($fonts,'demi');
				    text-transform: uppercase;
				    font-size: 10px;
				    font-weight: bold;
				    line-height: 1.5;
				    letter-spacing: 1px;
				    color: #ffffff;
				    padding-left: 5px;

				    @media screen and (max-width:480px){
				    	padding-left:0;
				    }

			    }

		    	&-play-wrp {
				    display: -webkit-box;
				    display: -ms-flexbox;
				    display: flex;
				    -webkit-box-orient: vertical;
				    -webkit-box-direction: normal;
				    -ms-flex-direction: column;
				    flex-direction: column;
				    -webkit-box-align: center;
				    -ms-flex-align: center;
				    align-items: center;
				}
		    }


		    &__play {
			    display: -webkit-box;
			    display: -ms-flexbox;
			    display: flex;
			    -webkit-box-pack: center;
			    -ms-flex-pack: center;
			    justify-content: center;
			    -webkit-box-align: center;
			    -ms-flex-align: center;
			    align-items: center;
			    width: 126px;
			    height: 126px;
			    max-width: 126px;
			    max-height: 126px;
			    position: relative;
			    opacity:1;

			    span {
			    	width:100%;
			    	height:100%;
				    border-radius:100%;
			    	background:#FFF;
				    position:absolute;
				    display:block;
				    transition: 0.3s all;
				    opacity:1;
				    top:0;
				    left:0;

			    }


		    	@media screen and (max-width:480px){
			        margin-bottom: 0;
			        width: 88px;
			        height: 88px;
		    	}

		    	img {
				    display: -webkit-box;
				    display: -ms-flexbox;
				    display: flex;
				    width: 126px;
				    height: 126px;
				    position:absolute;
				    top:0;
				    left:0;

				    @media screen and (max-width:480px){
				    	width:88px;
				    	height:88px;
				    }
		    	}

		    	&:hover {
		    		cursor:pointer;
		    		span {
					    -webkit-transition: 0.3s all;
					    transition: 0.3s all;
					    -webkit-transform: scale(1.15);
					    transform: scale(1.15);
		    		}
		    	}
		    }

		}


	}
