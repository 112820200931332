
	/**
	*
	* 		DEtails
	*			- The page details will go hehre
	*
	**/
	
	.details {
		margin-top:-100px;
		z-index:1;

		@media all and (max-height:729px), (max-width:1000px){
			margin-top:0;
		}

		@media all and (max-height:450px){
			margin-top:100px;
		}

		.header-details {

		    &__wrp {
			    width: 100%;
			    max-width: 862px;
			    margin:auto;
			    display: -webkit-box;
			    display: -ms-flexbox;
			    display: flex;
			    -webkit-box-orient: vertical;
			    -webkit-box-direction: normal;
			    -ms-flex-direction: column;
			    flex-direction: column;

			    &-col {
				    margin-top: 23px;
				    display: -webkit-box;
				    display: -ms-flexbox;
				    display: flex;
				    width: 100%;

				    @media screen and (max-width:767px){
				        -webkit-box-orient: vertical;
				        -webkit-box-direction: reverse;
				        -ms-flex-direction: column-reverse;
				        flex-direction: column-reverse;
				    }

				    @media screen and (max-width:480px){
				    	margin-top:0;
				    }
			    }
		    }


		    &__btn {
		    	&-container {
		    		display:flex;
		    		align-items:center;
		    	}
		    	&-down {
				    -webkit-transition:0.3s all;
				    transition:0.3s all;
				    font-family:"PP Neue Montreal";
				    font-weight:bold;
				    font-size:11px;
				    line-height:8px;
				    letter-spacing:1.1px;
				    text-align:center;
				    color:#fff;
				    padding:34px 54px 32px;
				    border:solid 2px #fff;
				    text-decoration:none;
				    border-radius:100px/40px;
				    position:relative;
				    display:inline-block;
				    margin:0 auto;
				    cursor:pointer;
				    @media all and (max-width:767px){
				    	margin-bottom:80px;
				    }
				    @media all and (max-width:480px){
				    	margin-bottom:100px;
				    }
				    &:after {
					    -webkit-transition:0.3s all;
					    transition:0.3s all;
					    content:"";
					    position:absolute;
					    top:113px;
					    left:calc(50% - 4px);
					    width:8px;
					    height:93px;
					    background:url("../../../img/home/arrow-down.svg") no-repeat center bottom;
					    background-size:cover;
				    }
				    &:hover {
					    border-color:#8D673F;
					    background:#8D673F;
					    &:after {
					    	top:123px;
					    }
				    }
		    	}
		    }



		    &__img-wrp {

			    margin-top:27px;
			    margin-left:20px;
			    max-width:650px;
			    width:100%;
			    height:1178px;
			    position:relative;
			    overflow:hidden;

			    img {
				    margin-top:54px;
				    max-width:593px;
				    width:100%;
				    height:100%
			    }

			    iframe {
				    margin-top:54px;
				    position:absolute;
				    z-index:-1;
				    width:110%;
				    height:110%;
				    top:-8%;
				    left:-3%;
			    }

			    @media all and (max-width:1199px){
			    	img {
			    		margin-top:0;
			    	}
			    }

			    @media all and (max-width:991px){
			        height:900px;
			    }

			    @media all and (max-width:767px){
			        //height:auto;
			        //max-width:400px;
			        //height:100%;
			        //height:1300px;
			        margin:0;
			        max-width:100%;
			        width:100%;

			        img {
				        max-width:300px;
				        width:100%;
				        margin-left:0;
			        }

			        iframe {
			        	transform:none !important;
			        	position:relative;
				        top:-10%;
				        left:-25%;
				        width:150%;
				        height:100%;
				    }
			    }

			    @media all and (max-width:600px){
			    	height:1000px;
			    }

			    @media all and (max-width:480px){
			    	img {
				        width:60%;
				        max-width:200px;
			    	}
			    }
		    }

		    &__texts {

		    	&-btns {
				    max-width:475px;
				    width:100%;
				    padding-top:243px;
				    position:relative;
				    z-index:2;
				    margin-left:62px;
		    		@media all and (max-width:1199px){
		    			padding-top:140px;
		    		}
		    		@media all and (max-width:767px){
				        padding-top:0;
				        margin-bottom:40px;
				        left:0;
				        margin-left:auto;
				        margin-right:auto;
		    		}
		    	}

		    }

		    &__uppercase-img {
			    max-width:354px;
			    margin-left:-2px;
		    }

		    &__text-number {
			    margin-bottom:4px;
			    font-family:"PP Neue Montreal";
			    font-size:68px;
			    line-height:1.2;
			    letter-spacing:-1.36px;
			    color:#ffffff;
			    font-weight:normal;
			    span {
				    display:inline-block;
				    margin-left:11px;
				    font-size:19px;
				    letter-spacing:-0.38px;
			    }
			    @media all and (max-width:360px){
			    	font-size:50px;
			    }
		    }

		    &__title-2 {
			    font-family:"PP Neue Montreal";
			    text-transform:uppercase;
			    font-size:11px;
			    font-weight:bold;
			    line-height:1.5;
			    letter-spacing:1.1px;
			    color:#ffffff;
			    @media all and (max-width:480px){
			    	padding-left:0;
			    }
		    }

		    &__text {
			    font-family:"GT Sectra";
			    max-width:432px;
			    margin-top:14px;
			    margin-bottom:30px;
			    font-size:24px;
			    line-height:1.42;
			    letter-spacing:-0.24px;
			    color:#8d673f;
			    font-weight:normal;
			    @media all and (max-width:480px){
			        margin-top:20px;
			        padding-left:0;
			        line-height:1.4;
			        font-size:22px;
			    }
		    }

		    &__number {
		    	&-wrp {
				    display:-webkit-box;
				    display:-ms-flexbox;
				    display:flex;
				    -webkit-box-pack:justify;
				    -ms-flex-pack:justify;
				    justify-content:space-between;
				    -webkit-box-align:end;
				    -ms-flex-align:end;
				    align-items:flex-end;
				    max-width:432px;
		    	}
		    }

		    &__price {
			    display:-webkit-box;
			    display:-ms-flexbox;
			    display:flex;
			    -webkit-box-orient:vertical;
			    -webkit-box-direction:normal;
			    -ms-flex-direction:column;
			    flex-direction:column;
			    -webkit-box-align:center;
			    -ms-flex-align:center;
			    align-items:center;
			    font-family:"PP Neue Montreal";
			    font-size:24px;
			    font-weight:bold;
			    letter-spacing:-0.48px;
			    border:solid 2px #8d673f;
			    border-radius:16px;
			    padding:16px;
			    padding-top:22px;
			    padding-bottom:12px;
			    color:#ffffff;

			    .currency {
				    font-size:11px;
				    letter-spacing:0.22px;
				    display:inline-block;
				    margin-top:5px;
			    }
		    }

		    &__quantity {
			    display:-webkit-box;
			    display:-ms-flexbox;
			    display:flex;
			    -webkit-box-align:center;
			    -ms-flex-align:center;
			    align-items:center;
			    -webkit-box-pack:justify;
			    -ms-flex-pack:justify;
			    justify-content:space-between;
			    width:145px;
			    height:68px;
			    background-color:#181818;
			    border-radius:6px 0 0 6px;
			    padding:10px;

			    + .btn-brown {
				    max-width:none;
				    width:calc(100% - 179px);
				    height:68px;
			    	@media all and (max-width:360px){
			    		width:calc(100% - 160px);
			    	}
			    }

			    &-wrp {
			    	display:flex;
			    }

			    &-max {    
		    		background: #fff;
				    border-radius: 0 6px 6px 0;
				    width: 35px;
				    height: 68px;
				    display: flex;
				    cursor:pointer;

			    	span {
			    		transform: rotate(90deg);
					    text-transform: uppercase;
					    color: #000;
					    display: block;
					    font-size: 11px;
					    width: 68px;
					    margin: auto;
					    font-family: "integral-cf-demi";
					    text-align: center;
			    	}

			    	&:hover {
			    		background:#8d673f;
			    		span {
			    			color:#FFF;
			    		}
			    	}
			    }

			    input {
				    font-family:"PP Neue Montreal";
				    width:60%;
				    font-size:31px;
				    font-weight:bold;
				    color:#ffffff;
				    text-align:center;
				    background:transparent;
				    border:0;
			    }

			    .minus, .plus {
				    font-family:"PP Neue Montreal";
				    font-weight:bold;
				    font-size:25.5px;
				    color:#ffffff;
				    padding:6px;
				    &:hover {
				    	cursor:pointer;
				    }
			    }
		    }


		    &__btn {
		    	&-wrp {
				    margin-top:49px;
				    display:-webkit-box;
				    display:-ms-flexbox;
				    display:flex;
				    -webkit-box-pack:justify;
				    -ms-flex-pack:justify;
				    justify-content:space-between;
				    max-width:432px;

		    		a {
					    max-width:212px;
					    width:calc(50% - 6px);
					    @media all and (max-width:480px){
					        min-width:0;
					        padding:10px;
					        height:60px;
						    img {
						        width:28px;
						        margin-left:5px;

						    }
						}
		    		}
		    	}
		    }

		    &__actions {
			    margin:10px 0;
			    width:calc(100% - 179px);
			    float:left;

			    > * {
			    	//display:none; 
			    	color:#FFF;
				    font-family:"PP Neue Montreal";

				    p {
				    	display:flex;
						line-height:40px;
						margin-bottom:10px;
					    img {
					    	max-width:40px;
					    	margin-right:15px;
					    }
					}
			    
				    a {
				    	color:#8d673f;
				    	margin-top:10px;
				    	font-size:80%;
				    }
			    }

			    [data-error] {
			    	color:#8d673f;
			    }
		    	
		    }

		    &__author-ape {
		    	clear:both;
			    margin-top:27px;
			    display:-webkit-box;
			    display:-ms-flexbox;
			    display:flex;
			    -webkit-box-align:center;
			    -ms-flex-align:center;
			    align-items:center;
			    -webkit-box-pack:start;
			    -ms-flex-pack:start;
			    justify-content:flex-start;
			    padding:10px;
			    padding-top:16px;
			    padding-left:22px;
			    width:100%;
			    max-width:432px;
			    height:116px;
			    border-radius:6px;
			    background:#181818;
			    &-text {
				    font-family:"PP Neue Montreal";
				    text-transform:uppercase;
				    font-size:10px;
				    line-height:1.4;
				    font-weight:bold;
				    color:#ffffff;
				    letter-spacing:1px;
				    a {
					    font-family:"PP Neue Montreal";
					    font-size:10px;
					    font-weight:bold;
					    color:#ffffff;
				    }
			    }
			    &-img {
				    width:60px;
				    margin-right:9px;
				    margin-top:-12px;
			    }
		    }


		}
	}