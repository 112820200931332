
	/**
	*
	* 		TABS
	*			- The tabs component goes here
	*
	**/

	.tabs {

		&__wrp {
		    margin-top: 7px;
		    width: 100%;
		    max-width: 862px;
		    margin-right: auto;
		    margin-left: auto;
		    display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
		    -webkit-box-orient: vertical;
		    -webkit-box-direction: normal;
		    -ms-flex-direction: column;
		    flex-direction: column;
		}

		&__top {
		    margin-top: 10px;
		    display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
		    -ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		    //height:629px;
		    position:relative;
		}

		&__content {
		    min-width: calc(100% + 158px);
		    margin-left: -83px;
		    //height: 629px;
		    background: #231c13;
		    color: #ffffff;
		    border-radius: 6px;
		    font-size: 16px;
		    font-weight: 500;
		    line-height: 1.5;
		    letter-spacing: -0.32px;
		    margin-bottom: 47px;
		    //position:absolute;
		    -webkit-animation-duration: 1.5s;
		    animation-duration: 1.5s;
		    -webkit-animation-fill-mode: both;
		    animation-fill-mode: both;
		    -webkit-animation-name: fadeOut;
		    animation-name: fadeOut;
		    display:none;

		    &.active {
			    -webkit-animation-name:fadeIn;
			    animation-name:fadeIn;
			    display:block;
		    }

		    &-img {
			    max-width: 1144px;
			    width: calc(100% + 124px);
			    margin-top: -29px;
			    margin-left: -50px;
		    }

		    @media screen and (max-width:1199px){
		        min-width: 100%;
		        margin-left: 0;
		        height: auto;
		        &-img {
			        width: calc(100% + 24px);
			        margin-top: -15px;
			        margin-left: -12px;
		        }
		    }
		}

		&__btn {
			&-wrp {
				&-plus {
				    display: -webkit-box;
				    display: -ms-flexbox;
				    display: flex;
				    width: 80%;

				    a {
					    -webkit-box-pack: justify;
					    -ms-flex-pack: justify;
					    justify-content: space-between;
					    width: 319px;
					    height: 66px;

					    @media screen and (max-width:400px){
					        padding-left: 15px;
					        padding-right: 15px;
					    }

				    }

				}
			}
		}

		&__controls {
			margin-top:60px;
			display:flex;
		}

		&__btns {
		    min-height: 40px;
		    width: 20%;
		    display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
		    -webkit-box-pack: end;
		    -ms-flex-pack: end;
		    justify-content: flex-end;
		    -webkit-box-align: center;
		    -ms-flex-align: center;
		    align-items: center;
		    list-style-type: none;
		    margin-bottom: 20px;
		    position: relative;
		    left: 2px;

		    @media screen and (max-width:480px){
		    	width:30%;
		    }

		    .tabs__btn {
			    margin-top: 20px;
			    padding: 4px;
			    margin-left: 3px;
			    margin-right: 3px;

			    &:hover {
			    	cursor:pointer;
			    }

			    span {
				    display: -webkit-box;
				    display: -ms-flexbox;
				    display: flex;
				    width: 12px;
				    height: 12px;
				    border-radius: 50%;
				    background: #ffffff;
			    }

			    &.active span {
			    	background: #8d673f;
			    }

		    }
		}

	}