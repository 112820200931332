
	/**
	*
	* 		Epilogue
	*			- The epilogue component goes here
	*
	**/

	.epilogue {
		margin-bottom:152px;

		@media all and (max-width:480px){
			margin-bottom:110px;
		}

		&__wrp {
		    max-width:854px;
		    width:100%;
		    margin-right:auto;
		    margin-left:auto;
		}

		&__title {
		    font-family:map-get($fonts,'montreal');
		    margin-bottom:15px;
		    font-size:50px;
		    font-weight:bold;
		    line-height:1.35;
		    letter-spacing:-1px;
		    color:#fff;
			@media all and (max-width:480px){
				font-size:36px;
			}
			
			&-2 {
			    font-family:map-get($fonts,'montreal');
			    text-transform:uppercase;
			    margin-bottom:19px;
			    font-size:14px;
			    font-weight:bold;
			    letter-spacing:-0.28px;
			    color:#fff;
				@media all and (max-width:480px){
					font-size:12px;
				}
			}
		}

		.place-top {
			position:absolute;
			white-space:nowrap;
		}

		&__text,
		&__text a {
		    font-family:map-get($fonts,'montreal');
		    color:#3d3d3d;
		    line-height:1.46;
		    letter-spacing:-0.24px;
		    font-size:12px;
		    font-weight:bold;
		}
	}