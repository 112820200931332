
	/**
	*
	* 		FORMS
	*			- The side navigation settings will go here
	*
	**/
	.form {
		width:100%;
		&__wrp {
		    width: 100%;
		    display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
		    -webkit-box-orient: vertical;
		    -webkit-box-direction: normal;
		    -ms-flex-direction: column;
		    flex-direction: column;
		}

		&__text-error {
		    position: absolute;
		    bottom: -27px;
		    right: 0;
		    margin-top: 10px;
		    font-size: 13px;
		    font-weight: bold;
		    line-height: 1.5;
		    letter-spacing: -0.26px;
		    color: #ff3d3d;
		    text-align: right;
		    display: none;

			@media screen and (max-width:500px){
		        bottom: -35px;
		        left: 0;
		        text-align: left;
			}
		}

		&_disclaimer {
		    margin-top: 12px;
		    color: #444444;
		    letter-spacing: -0.22px;
		    font-size: 11px;
		    font-weight: 500;
		    line-height: 1.5;
		    padding-left: 48px;
		    position: relative;
		}

		&__textarea {
		    background: transparent;
		    width: 100%;
		    height: 577px;
		    resize: none;
		    border: 1px solid #ffffff;
		    border-radius: 6px;
		    padding: 25px;
		    font-size: 16px;
		    font-weight: 500;
		    line-height: 1.5;
		    letter-spacing: -0.32px;
		    color: #ffffff;
		    @media screen and (max-width:480px){
		    	height:400px;
		    }

		    &::-webkit-input-placeholder,
		    &::-moz-placeholder,
		    &:-ms-input-placeholder,
		    &::-ms-input-placeholder,
		    &::placeholder {
		    	color:#cecece;
		    }

		    &__text {
			    margin-top: 10px;
			    margin-right: 10px;
			    font-size: 13px;
			    font-weight: bold;
			    line-height: 1.5;
			    letter-spacing: -0.26px;
			    color: #cecece;

			    &-social {
				    margin-top: 16px;
				    margin-bottom: 25px;
				    font-size: 16px;
				    font-weight: 500;
				    line-height: 1.5;
				    letter-spacing: -0.32px;
				    color: #fff;
			    }
		    }
		}

		&__info {
			&-wrp {
			    width: 100%;
			    position: relative;
			    margin-bottom: 78px;
			    display: -webkit-box;
			    display: -ms-flexbox;
			    display: flex;
			    -webkit-box-pack: justify;
			    -ms-flex-pack: justify;
			    justify-content: space-between;
			    -ms-flex-wrap: wrap;
			    flex-wrap: wrap;

			    @media screen and (max-width:480px){
			    	margin-bottom:40px;
			    }

				+ .form__text-error {
					bottom:0;
				
					@media screen and (max-width:500px){
						bottom:-24px;
					}
				
				}

				.checkbox-error {
					&.form__text-error {
						bottom:-12px;
						@media screen and (max-width:500px){
							bottom:-30px;
						}
					}
				}

				&.error {
					&.form__text-error {
						display:block;
					}
				}
			}
		}

		&__input {
		    border: 0;
		    border-bottom: 2px solid #ffffff;
		    background: transparent;
		    width: 100%;
		    padding: 5px;
		    height: 34px;
		    font-weight: 500;
		    font-size: 16px;
		    color: #fff;

		    &::-webkit-input-placeholder,
		    &::-moz-placeholder,
		    &:-ms-input-placeholder,
		    &::-ms-input-placeholder,
		    &::placeholder {
		    	color:#fff;
		    }

			&-col {
			    display: -webkit-box;
			    display: -ms-flexbox;
			    display: flex;
			    -webkit-box-align: center;
			    -ms-flex-align: center;
			    align-items: center;
			    width: calc(50% - 20px);
			    position: relative;

			    @media screen and (max-width:480px){
			    	width:100%;
			    	margin-bottom:40px;

			    	&:last-child {
			    		margin-bottom:0;
			    	}
			    }

			    &.btn-col {
				    -webkit-box-pack: end;
				    -ms-flex-pack: end;
				    justify-content: flex-end;
			    }
			}

			&-icon {
			    position: relative;
			    top: 4px;
			    margin-right: 10px;
			}
		}

		&__checkbox {
		    display: none;
		    margin-top: 17px;

			&:checked + label:before {
				content: "✓";
			}

			&:disabled + label:before {
			    background: transparent;
			    color: transparent;
			}

			& + label {
			    margin-top: 12px;
			    color: #ffffff;
			    letter-spacing: -0.22px;
			    font-size: 11px;
			    font-weight: 500;
			    line-height: 1.5;
			    padding-left: 48px;
			    position: relative;

			    &:hover {
			    	cursor: pointer;
			    }

			    &:before {
				    content: "";
				    position: absolute;
				    top: -10px;
				    left: 0;
				    font-size: 24px;
				    background: transparent;
				    margin-right: 12px;
				    min-width: 35px;
				    min-height: 35px;
				    width: 35px;
				    height: 35px;
				    border-radius: 6px;
				    background: #ffffff;
				    color: #000000;
				    text-align: center;
				    text-indent: 0px;
				    -o-border-image: initial;
				    border-image: initial;
				    vertical-align: middle;
			    }

			}
		}


	}