
	/**
	*
	* 		CONTACT US
	*			- The contact us component goes here
	*
	**/

	.select-ape {
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;

		@media screen and (max-width:790px){
			width: calc(100% + 10px);
		}

		&__option {
		    width: 243px;
		    display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
		    margin-right: 10px;
		    margin-bottom: 10px;

			img {
			    width: 100%;
			    height: auto;
			}

			@media screen and (max-width:790px){
		        max-width: 243px;
		        width: calc(33.333% - 10px);
		        margin-right: 10px;

				img {
			        width: 100%;
			        height: auto;
				}
			}

			@media screen and (max-width:526px){
				width: calc(50% - 10px);
			}
		}
	}