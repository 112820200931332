
	/**
	*
	* 		CSS Animations & Frames
	*			- Any kind of SCSS Frames should go in here
	*
	**/
	
	$animations: (fadeIn, moveUp, fadeOut, ticker);

	//Fade In
	@keyframes fadeIn {
	    0% {
	    	opacity:0;
	    }
	    to {
	        opacity: 1;
	    }
	}

	@keyframes fadeOut {
		0% {
			opacity:1;
		}
		to {
			opacity:0;
		}
	}


	//Move Up
	@keyframes moveUp {
	    0% {
	        -webkit-transform: translate3d(0, 60px, 0);
	        transform: translate3d(0, 60px, 0);
	    }
	    to {
	        -webkit-transform: translate3d(0, 0, 0);
	        transform: translate3d(0, 0, 0);
	    }
	}

	@keyframes pulse {
	  	0% {
	    	transform: scale(0.95);
	    	box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	  	}
	  
	  	70% {
	    	transform: scale(1);
	    	box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	  	}
	  
	  	100% {
	    	transform: scale(0.95);
	    	box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	  	}
	}

	@keyframes circle {
		0%	{
			transform:scale3d(.9,.9,.9);
		}
		50% {
			transform:scale3d(1.1,1.1,1.1);
		}
		to {
			transform:scale3d(.9,.9,.9);
		}
	}


	@keyframes build {
		0% {
			transform:translateY(90px);
			opacity:0;
		}
		25% {
			transform:translateY(-90px);
			opacity:0;
		}
		50% {
			transform:translateY(0);
			opacity:1;
		}
		75% {
			transform:translateY(90px);
			opacity:0;
		}
		100% {
			transform:translateY(90px);
			opacity:0;
		}
	}

	[data-animate*="fadeIn"] {
		opacity:0;
	}

	.is-inview[data-animate] {
	    -webkit-animation-duration: 2.5s;
	    animation-duration: 2.5s;
	    -webkit-animation-fill-mode: both;
	    animation-fill-mode: both;

	    @each $a1 in $animations {
			&[data-animate="#{$a1}"] {
			    -webkit-animation-name: $a1;
			    animation-name: $a1;
			}
			@each $a2 in $animations {
				&[data-animate="#{$a1},#{$a2}"] {
				    -webkit-animation-name: $a1, $a2;
				    animation-name: $a1, $a2;
				}
			}
		}

	}

