
	/**
	*
	* 		Footer
	*			- The Footer styles will go here
	*
	**/

	.footer {
	    height: 194px;
	    background: #ffffff;

		@media screen and (max-width:480px){
			height:auto;
		}

		&__wrp {
		    width: 100%;
		    height: 194px;
		    max-width: 862px;
		    margin-right: auto;
		    margin-left: auto;
		    display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
		    -webkit-box-pack: justify;
		    -ms-flex-pack: justify;
		    justify-content: space-between;
		    -webkit-box-align: center;
		    -ms-flex-align: center;
		    align-items: center;

		    @media screen and (max-width:480px){
		        -webkit-box-orient: vertical;
		        -webkit-box-direction: reverse;
		        -ms-flex-direction: column-reverse;
		        flex-direction: column-reverse;
		        height: auto;
		    }
		}

		&__text {
		    font-size: 11px;
		    line-height: 1.55;
		    letter-spacing: -0.22px;
		    text-align: justify;
		    color: #000;
		    font-weight: 500;

		    @media screen and (max-width:480px){
		    	text-align:center;
		    }

			&-wrp {
			    margin-top: -17px;
			    max-width: 200px;

			    @media all and (max-width:480px){
			    	margin-top:0;
			    	margin-bottom:30px;
			    }

			}
		}

		&__title {
		    font-family:map-get($fonts,'extra');
		    font-size: 17px;
		    font-weight: bold;
		    color: #000;
		    line-height: 1.5;
		    margin-bottom: 7px;

		    @media screen and (max-width:480px){
		        text-align: center;
		    }
		}

		&__social {
			&-wrp {
			    margin-top: -30px;
			    display: -webkit-box;
			    display: -ms-flexbox;
			    display: flex;
			    -webkit-box-align: center;
			    -ms-flex-align: center;
			    align-items: center;

			    @media screen and (max-width:480px){
			    	margin-top:20px;
			    	margin-bottom:10px;
			    }

				a {
				    margin-left: 32px;
				    text-decoration: none;

				    @media screen and (max-width:480px){
				    	margin:0 10px;
				    }

				    img {
				    	width:30px;
				    }
				}
			}
		}

	}



