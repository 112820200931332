
	/**
	*
	* 		Home
	*			- Styles specific to the home page should go here
	*
	**/






	//Card Animation
	@keyframes card {
		0%	{
			opacity:0;
			transform:translateY(-150vh);
		}
		20% {
			opacity:1;
			transform:translateY(0);
		}
		40% {
			opacity:0;
			transform:translateY(150vh);
		}
		100% {
			opacity:0;
			transform:translateY(-150vh);
		}
	}


	@keyframes open {
		0% {
			opacity:0;
			transform:translateY(-150vh);
		}
		30% {
			opacity:1;
			transform:translateY(0);
		}
		100% {
			transform:scale(7);
		}
	}




	html {
		min-width:320px;
		&.scrolled {
			#pcards {
				.navigation {

					&__container {
						.navigation__logo-link {
							font-size:17px;
						}
						> .social {
							a,
							span {
								font-size:14px;
								span {
									width:185px;
								}
							}
							span {
								img {
									width:30px;
								}
								span {
									width:auto;
									font-size:20px;
								}
							}
						}
					}
				}
			}
		}

		.ReactModalPortal {
			z-index:2;
			position:relative;
		}
		#pcards {

			background:#181818;
			min-width:320px;


			#preloader {
				width:100%;
				height:100%;
				//background:#003fca;
				position:fixed;
				top:0;
				display:flex;
				z-index:9;

				.deck {
					opacity:0;
					animation:2s infinite card ease-in-out;
				}

				.title {
					fill:#FFF;
					transform:scale(0.8);
					transform-origin:center;
				}

				.bg {
					fill:#003fca;
				}

				.card {
					fill:#0F3CB1;
				}

				&[data-preloaded="true"] {
					top:-100vh;
					transition:top ease 0.1s;
					transition-delay:3s;
					.card {
						opacity:0;
						transition:0.3s opacity ease-out;
					}
					.bg {
						mask:url(#clip);
					}
					#Shuffling {
						opacity:0;
						transition:opacity 0.3s ease-out;
						transition-delay:1s;
					}
					#clip {
						path {
							transform-origin:center;
							animation:3s normal open ease-in-out;
							animation-fill-mode: forwards;
						}
					}
				}

				/*
				svg {
					max-width:80%;
					margin:auto;
				}
				*/
			}

			.navigation {
				height:auto;
				background:#000;
				position:absolute;
				top:0;
				z-index:2;

				.hello-bar {
					background:#003fca;
					color:#FFF;
					text-align:center;
					font-size:17px;
					font-weight:500;
					padding:10px;
					text-align:center;
					p {
						width:100%;
						font-family:map-get($fonts,'aventa');
						font-size:16px;
						@media all and (max-width:500px){
							font-size:12px;
						}
						@media all and (max-width:372px){
							font-size:10px;
						}
						a {
							cursor:pointer;
							font-weight:100;
							font-weight:bold;
							text-decoration:underline;
							color:#FFF;
							&:hover {
								//color:darken(#FFF,10%);
							}
						}
					}
				}

				.btn-open {
					display:none !important;
				}

				&__container {
					padding-top:20px;
					padding-bottom:20px;
					padding-left:5vw;
					padding-right:5vw;
					max-width:100%;

					.navigation__logo-link {
						font-size:20px;
						transition:0.3s ease font-size;
					}

					&.approved {
						.navigation__logo {
							padding:30px 0;
						}
					}

					> .coin {
						display:flex;
						margin:auto 0 auto auto;
						align-items:center;
						justify-content:center;
						p {
							font-size:11px;
							text-transform:uppercase;
							margin-right:15px;
							font-weight:bold;
							letter-spacing:0.5px;
							white-space:nowrap;
							color:#FFF;
							@media all and (max-width:800px){
								margin-right:10px;
							}
						}
						img {
							min-width:100px;
							@media all and (max-width:800px){
								min-width:75px;
							}
						}
					}
				}
			}

			.container {
				margin:0 auto;
				padding:0 5vw;
				width:100%;
			}


			.header {
				background:#181818;
				position: relative;
			    //padding-top: 25px;
			    height: 0;
			    //top:27px;
				z-index:1;
				width:100vw;
				height:100vh;
				max-height:100vh;

				@media all and (max-width:600px){
					min-height:calc( 90vh - 27px );
				}

				> div {
					position:absolute;
					top:0;
					left:0;
					width:100%;
					height:100%;
					min-width:320px;
					display:flex;
					> div {
						@extend .container;
						display:flex;

						@media all and (max-width:800px){
							flex-direction:column;
							justify-content:flex-end;
						}

						> div {
							max-width:50%;
							margin:auto 0 8vh;
							width:50%;

							@media all and (max-width:800px){
								width:100%;
								max-width:100%;
								margin:0;
								text-align:left;
								padding-bottom:2vh;
								height:auto;
								&:last-child {
									padding-bottom:4vh;
								}
							}


							.steps {
								background:none;
								height:auto;
								padding:0;
								img {
									height:89px;
								}
							}
							h1 {
								padding:30px 0;
								img {
									filter: invert(74%) sepia(57%) saturate(635%) hue-rotate(27deg) brightness(113%) contrast(88%);
									max-width:85%;
									@media all and (max-width:800px){
										max-width:100%;
									}
								}
								@media all and (max-width:800px){
									padding:2vh 0 0;
								}
							}
							p {
								font-family:map-get($fonts,'aventa');
								font-size:18px;
								line-height:24px;
								letter-spacing:-0.01em;
								color:#FFF;
								@media all and (max-width:800px){
									display:none;
								}
							}

							#tokenBtn {
								border-radius:50px;
								background:#FFF;
								padding:15px 25px;
								color:#000;
								border:none;
								display:flex;
								align-items:center;
								justify-content:center;
								line-height:normal;
								margin:auto 0 0 auto;
								cursor:pointer;
								font-size:125%;

								@media all and (max-width:405px){
									width:100%;
									font-size:4.5vw;
								}
								@media all and (max-width:320px){
									font-size:15px;
									padding:15px 0px;
								}

								&:hover {
									transition:0.3s ease all;
									background:#000;
									color:#FFF;
									img {
										filter:brightness(200);
									}
								}

								@media all and (max-width:800px){
									margin:0;
								}

								img {
									display:inline-block;
									padding-right:15px;
								}
								span {
									display:inline-block;
								}
							}
						}
					}
				}
				.video {
					position:relative;
					overflow:hidden;
					width:100vw;
					height:100vh;
					min-width:320px;

					iframe {
						pointer-events:none;
						position: absolute;
						top: 50%;
						left: 50%;
						width: 100vw;
						height: 100vh;
						transform: translate(-50%, -50%);
						@media (min-aspect-ratio: 16/9) {
						    /* height = 100 * (9 / 16) = 56.25 */
						    height: 56.25vw;
						}
						@media (max-aspect-ratio: 16/9) {
						    /* width = 100 / (9 / 16) = 177.777777 */
						    width: 177.78vh;
						}
					}
				}
			}

		}
	}