
	/**
	*
	* 		NAVIGATION
	*			- The main navigation styles will go here
	*
	**/

	.navigation {
	    display: block;
	    height: 56px;
	    position: relative;
	    z-index: 20;
	    width: 100%;

	    &__container {
		    padding-top: 112px;
		    position: relative;
		    display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
		    height: 100%;
		    -webkit-box-align: center;
		    -ms-flex-align: center;
		    align-items: center;
		    -webkit-box-pack: justify;
		    -ms-flex-pack: justify;
		    justify-content: space-between;
		    max-width: 1218px;
		    width: 100%;
		    padding-right: 15px;
		    padding-left: 15px;
		    margin-right: auto;
		    margin-left: auto;

		    @media screen and (max-width:991px){
		    	padding-top:80px;
		    }

		    @media screen and (max-width:767px){
		    	padding-top:60px;
		    }
	    }

	    &__logo-link {
		    margin-right: 15px;
		    position: relative;
		    top: -2px;
		    font-size: 17px;
		    font-family: "integral-cf-extra";
		    font-weight: 900;
		    color: #ffffff;
		    text-decoration: none;

		    &:hover {
			    color: #ffffff;
			    text-decoration: none;
		    }

	    }

	}