
	/**
	*
	* 		Home
	*			- Styles specific to the home page should go here
	*
	**/

	#psubmit {

		background:#181818;

		.navigation {
			height:auto;

			&__container {
				padding-top:80px;
			}

			.btn-green {
				background:#11f0bd;
				border:2px solid #11f0bd;
				font-family:map-get($fonts,'aventa');
				font-size:19px;
				color:#000;
				border-radius:50px;
				text-transform:none;
				cursor:default;
			}
		}


		.submit {
			max-width:1218px;
			padding:120px 100px;
			margin:0 auto;

			@media all and (max-width:750px){
				padding:120px 0%;
			}

			@media all and (max-width:600px){
				padding:120px 20px;
			}

			h1 {
				font-family:map-get($fonts,'aventa');
				font-size:75px;
				color:#FFF;
				line-height:70px;
				letter-spacing:-0.5px;
				font-weight:600;

				> span {
					display:inline-block;
					transform:scale(.5);
					position:relative;
					top:-10px;
					left:5px;
				}

				div.select {
					display:inline-block;
					position:relative;
					top:-13px;
					padding-left:15px;
					@media all and (max-width:600px){
						//top:0;
						//position:static;
					}
					.dropdown {
						.selected {
							font-family:map-get($fonts,'aventa');
							font-size:18px;
							border:2px solid #11f0bd;
							white-space:nowrap;
							background:#181818;
							border-radius:50px;
							line-height:normal;
							padding:5px 20px;
							position:relative;
							cursor:pointer;
							transition:0.2s ease all;
							img {
								width:20px;
								margin-left:5px;
								filter:invert(75%) sepia(31%) saturate(1159%) hue-rotate(108deg) brightness(100%) contrast(90%);
							}

							@media all and (max-width:400px){
								white-space:normal;
								position:relative;
								line-height:normal;
								max-width:250px;
								padding-right:40px;
								min-height:70px;
								display:flex;
								span {
									margin:auto 0;
								}
								img {
									position:absolute;
									top:30px;
									right:10px;
								}
							}
						}
						.dropdown {
							background:#FFF;
							border-radius:20px;
							position:absolute;
							display:none;
							left:20px;
							@media all and (max-width:800px){
								right:0;
								left:auto;
							}
							@media all and (max-width:600px){
								right:auto;
								margin:0;
								min-width:320px;
								width:100%;
								left:0;
							}
							@media all and (max-width:400px){
								margin-top:-30px;
							}
							li {
								padding:10px 20px;
								font-size:29px;
								line-height:56px;
								color:#181818;
								border-top:2px solid #e1e2e5;
								display:flex;
								min-width:240px;
								cursor:pointer;
								&:hover {
									background:#e1e2e5;
								}
								&:first-child {
									border-top:none;
									border-radius:20px 20px 0 0;
								}
								&:last-child {
									border-radius:0 0 20px 20px;
								}
								&[disabled] {
									opacity:.1;
									cursor:not-allowed;
								}
								span {
									font-size:16px;
									line-height:56px;
									margin:auto 0 auto auto;
								}
							}
						}
					}
					&.open {
						.dropdown {
							.selected {
								background:#11f0bd;
								color:#000;
								img {
									filter:invert(0);
									transform:rotate(180deg);
								}
							}
							.dropdown {
								display:block;
							}
						}
					}
					select {
						display:none;
					}
				}
			}

			form {

				.story {
					padding-top:30px;
					textarea {
						width:100%;
						min-height:576px;
						background:#181818;
						color:#FFF;
						font-family:map-get($fonts,'aventa');
						font-weight:400;
						font-size:16px;
						letter-spacing:-0.02em;
						line-height:normal;
						padding:30px 20px;
						border-radius:5px;
						border:1px solid #444444;
					}
					.limit {
						text-align:right;
						color:#FFF;
						font-family:map-get($fonts,'aventa');
						padding-top:5px;
						font-weight:500;
						letter-spacing:-0.02em;
						font-size:13px;
					}
				}

				.upload {
					padding:100px 0 0;
					label {
						width:125px;
						height:125px;
						background:#11f0bd;
						color:#000;
						font-weight:bold;
						display:flex;
						border-radius:100%;
						margin:0 auto;
						span {
							margin:auto;
							font-size:50px;
						}
						cursor:pointer;
						&:hover {
							background:darken(#11f0bd,10%);
						}

						+ input[type="file"] {
							display:none;
						}
					}
					p {
						font-size:10px;
						color:#FFF;
						text-align:center;
						margin-top:40px;
					}
				}

				.rows {
					display:flex;
					flex-direction:column;
					padding-top:100px;

					@media all and (max-width:750px){
						display:block;
					}

					h2 {
						color:#FFF;
						font-family:map-get($fonts,'aventa');
						font-size:34.5px;
						line-height:62.31px;
						letter-spacing:-0.01em;
						font-weight:600;
						padding-bottom:20px;
					}

					.row {
						width:100%;
						color:#FFF;
						display:flex;
						justify-content:space-between;
						padding-bottom:30px;

						@media all and (max-width:900px){
							flex-direction:column;
						}

						.field {
							padding-bottom:40px;
							display:flex;
							width:49%;
							max-width:550px;

							@media all and (max-width:900px){
								width:100%;
								max-width:100%;
							}

							> img {
								padding-right:15px;
								filter:invert(75%) sepia(31%) saturate(1159%) hue-rotate(108deg) brightness(100%) contrast(90%);
								width:50px;
							}

							sup {
								font-size:18px;
							}

							.textField {
								position:relative;
								width:100%;
								input[type="text"] {
									font-family:map-get($fonts,'aventa');
									letter-spacing:-0.02em;
									background:#FFF;
									border-width:0;
									padding:30px 30px 30px 40px;
									height:30px;
									width:100%;
									color:#000;
									border-radius:50px;
									&:placeholder {
										color:#a4a4a4;
									}
								}

								&:before {
									color:#000;
									position:absolute;
									font-size:18px;
									left:20px;
									top:18px;
								}

								&.hashtag:before {
									content:'#';
								}

								&.at:before {
									content:'@';
								}
							}

							&.consent {
								display:flex;
								margin:auto auto auto 0;
								flex-direction:row;

								input[type="checkbox"] + label {
									background:#FFF;
									border-radius:2px;
									width:35px;
									height:30px;
									margin-right:10px;
									cursor:pointer;
									img {
										display:none;
										width:100%;
										height:100%;
										padding:0;
									}
								}

								input[type="checkbox"]:checked + label {
									background:#01C29D;
									img {
										display:block;
									}
								}

								input[type="checkbox"] {
									display:none;
								}

								p { 
									font-size:11px;
									line-height:normal;
									font-family:map-get($fonts,'aventa');
									font-weight:700;
									margin:auto auto auto 0;
									color:#FFF;
								}
							}
						}

						&.btnSubmit {
							display:flex;
							padding-top:50px;
							border-top:1px solid #444444;
							div {
								display:flex;
								flex-direction:column;
								margin:auto 0 0 auto;
								font-size:11px;
								font-family:map-get($fonts,'main');
								line-height:32px;
								color:#585858;

								@media all and (max-width:750px){
									margin:0;
									padding-top:40px;
								}

								button {
									max-width:175px;
									border-radius:80px;
									font-family:map-get($fonts,'aventa');
									text-transform:capitalize;
									font-size:18px;

									svg {
										width:25px;
										margin-left:10px;
									}

									&[disabled] {
										background:#666;
									}

								}
							}
						}

					}
				}

			}

		}
	}