
	/**
	*
	* 		Confidential
	*			- Confidential Section of Home Page
	*
	**/
	
	.confidential {
	    padding-top:250px;
	    overflow:hidden;

	    @media all and (max-width:767px){
	    	padding-top:20px;
	    	padding-bottom:122px;
	    }

	    &__wrp {
		    display:-webkit-box;
		    display:-ms-flexbox;
		    display:flex;
		    -webkit-box-orient:vertical;
		    -webkit-box-direction:normal;
		    -ms-flex-direction:column;
		    flex-direction:column;
		    max-width:880px;
		    width:100%;
		    margin-right:auto;
		    margin-left:auto;
	    }

	    &__title {
		    font-family:map-get($fonts,'montreal');
		    margin-bottom:4px;
		    color:#ffffff;
		    font-weight:bold;
		    font-size:50px;
		    line-height:1.35;
		    letter-spacing:-1px;
		    padding-left:10px;

		    span {
			    font-family:map-get($fonts,'beatrice');
			    font-style:italic;
			}

			@media all and (max-width:576px){
				font-size:36px;
		        padding-left:0;
			}

			@media all and (max-width:767px){
				margin-top:25px;
		        margin-bottom:25px;
			}

	    	&-text {
			    margin-bottom:225px;
			    font-size:60px;
			    font-family:map-get($fonts,'montreal');
			    color:#ffffff;
			    text-align:center;
			    font-weight:bold;
			    line-height:0.96;
			    min-height:400px;
			    .font2 {
				    font-family:map-get($fonts,'beatrice');
				    font-weight:600;
				    font-style:italic;
			    }
			    .brown {
				    font-weight:bold;
				    color:#8d673f;
			    }

			    @media all and (max-width:991px){
			        margin-bottom:120px;
			        font-size:40px;
			    }

			    @media all and (max-width:767px){
        			padding-top:80px;
			    }

			    @media all and (max-width:480px){
			    	font-size:30px;
			    }

			    @media all and (max-width:400px){
			    	font-size:26px;
			    }

			    &-wrp {
				    display:-webkit-box;
				    display:-ms-flexbox;
				    display:flex;
				    -webkit-box-orient:vertical;
				    -webkit-box-direction:normal;
				    -ms-flex-direction:column;
				    flex-direction:column;
				    position:relative;
			    }
	    	}
	    }


	    &__text {
	    	&-rotate {
			    position:relative;
			    padding-top:27px;
			    padding-bottom:3px;
			    left:50%;
			    margin-right:-50%;
			    -webkit-transform:translate(-50%,0) rotate(-8deg);
			    transform:translate(-50%,0) rotate(-8deg);
			    font-family:map-get($fonts,'beatrice');
			    font-size:203px;
			    color:#000000;
			    background:#ffffff;
			    line-height:1;
			    letter-spacing:-4.06px;
			    font-weight:800;
			    width:calc(100vw + 1000px);
			    padding-left:120px;
			    padding-right:140px;
			    text-align:center;
			    @media all and (max-width:1600px){
			        padding-left:120px;
			        padding-right:120px;
			        font-size:160px;
			    }
			    @media all and (max-width:1300px){
        			font-size:140px;
			    }
			    @media all and (max-width:1199px){
			    	font-size:120px;
			    }
			    @media all and (max-width:991px){
			        font-size:90px;
			        letter-spacing:-1px;
			        padding-top:15px;
			        padding-bottom:10px;
			    }
			    @media all and (max-width:767px){
			        font-size:64px;
			        letter-spacing:0.5px;
			    }
			    @media all and (max-width:576px){
			        font-size:40px;
			        letter-spacing:0.5px;
			        padding-left:50px;
			        padding-right:50px
			    }
	    	}
	    }

	    &__img {
	    	&-wrp {
			    padding-left:20px;
			    margin-top:-23px;
			    display:-webkit-box;
			    display:-ms-flexbox;
			    display:flex;
			    -webkit-box-align:center;
			    -ms-flex-align:center;
			    align-items:center;
			    -webkit-box-pack:end;
			    -ms-flex-pack:end;
			    justify-content:flex-end;
			    position:relative;
			    z-index:2;
			    @media all and (max-width:991px){
			    	margin-top:0;
			    }
	    	}
	    	&-text {
			    max-width:300px;
			    margin-right:56px;
			    @media all and (max-width:991px){
			        margin-right:20px;
			        width:calc(65% - 20px);
			    }
	    	}
	    	&-banana {
			    max-width:243px;
			    position:relative;
			    top:-78px;
			    margin-right:41px;
			    @media all and (max-width:991px){
			        top:-40px;
			        width:35%;
			        margin-right:0;
			    }
	    	}
	    }

	}