
	/**
	*
	* 		FEATURED STORY
	*			- The featured story component goes here
	*
	**/

	.featured-story {
	    padding-top:189px;
	    width:100vw;
	    overflow-x:hidden;
	    padding-bottom:60px;
	    position:relative;
	    z-index:1;

	    &__img-wrp {
		    position:relative;
		    width:100%;
		    max-width:2600px;
		    margin:0 auto;

		    @media all and (max-width:1500px){
		        padding-right:50px;
		        padding-left:50px;
		    }
	    
		    @media all and (max-width:767px){
		        margin-top:-130px;
		        max-width:500px;
		        margin-right:auto;
		        margin-left:auto;
		        padding-right:15px;
		        padding-left:15px;
		    }
	    }


	    &__img {
		    display:block;
		    width:100%;
		    max-width:1151px;
		    margin-right:auto;
		    margin-left:auto;
		    position:relative;
		    left:-118px;
		    top:64px;

		    img {
		    	width:100%;
		    }

		    @media all and (min-width:2700px){
		    	top:-50px;
		    }

		    @media all and (min-width:2500px){
		    	top:2px;
		    }

		    @media all and (max-width:2500px){
		    	top:7px;
		    }

		    @media all and (max-width:2400px){
		    	top:14px;
		    }
		    @media all and (max-width:2300px){
		    	top:14px;
		    }

		    @media all and (max-width:2200px){
		    	top:22px;
		    }

		    @media all and (max-width:2100px){
		    	top:21px;
		    }

		    @media all and (max-width:2000px){
		    	top:36px;
		    }

		    @media all and (max-width:1500px){
		    	width:99%;
		        left:0;
		        margin-left:0;
		        top:51px;
		    }

		    @media all and (max-width:1400px){
		        top:50px;
		    }

		    @media all and (max-width:1300px){
		    	width:117%;
		    	top:41px;
		    }

		    @media all and (max-width:1100px){
		    	top:6px;
		    }

		    @media all and (max-width:1000px){
		    	top:25px;
		    }

		    @media all and (max-width:767px){
		    	width:84%;
		    	top:35px;
		    }

		    @media all and (max-width:400px){
		    	top:29px;
		    }

		    @media all and (max-width:359px){
		    	top:20px;
		    }

	    	&-case {
			    position:absolute;
			    top:-115px;
			    left:calc(30% + 135px);
			    margin-right:-50%;
			    -webkit-transform:translate(-30%,0);
			    transform:translate(-30%,0);
			    max-width:1185px;

			    img {
			    	width:100%;
			    }

			    @media all and (max-width:1500px){
			        left:calc(47% - 50px);
			        top:-12%;
			    }
			    @media all and (max-width:767px){
			        left:calc(47% - 50px);
			        width:80%;
			        top:-10%;
			        left:50%;
			    }
	    	}
	    }

	    &__wrp {
		    width:100%;
		    margin-right:auto;
		    margin-left:auto;
	    }

	    &__feedback {
		    margin-top:144px;
		    display:-webkit-box;
		    display:-ms-flexbox;
		    display:flex;
		    -webkit-box-orient:vertical;
		    -webkit-box-direction:normal;
		    -ms-flex-direction:column;
		    flex-direction:column;
		    margin-bottom:264px;
		    width:100%;
		    max-width:858px;
		    margin-left:auto;
		    margin-right:auto;

		    @media all and (max-width:767px){
		    	margin-bottom:100px;
		    }

		    &-img {
		    	width:239px;
		    	position:relative;
		    	top:58px;

		    	&-wrp {
				    display:-webkit-box;
				    display:-ms-flexbox;
				    display:flex;
				    -webkit-box-pack:center;
				    -ms-flex-pack:center;
				    justify-content:center;
				    -webkit-box-align:end;
				    -ms-flex-align:end;
				    align-items:flex-end;
				    width:269px;
				    height:269px;
				    background:#6f5d70;
				    border-radius:12px;
				    position:relative;
				    &::after {
				    	width:100%;
				    	height:100px;
				    	background:#000;
				    	content:' ';
				    	position:absolute;
				    	bottom:-100px;
				    }
				}
		    }
	    }

	    &__text {
		    font-family:map-get($fonts,'montreal');
		    font-size:60px;
		    font-weight:bold;
		    line-height:1;
		    letter-spacing:-1.2px;
		    color:#fff;
		    position:relative;
		    left:8px;

	    	&-wrp {
			    margin-top:-41px;
			    width:100%;
			    max-width:610px;
			    margin-left:auto;
			    margin-right:auto;
	    	}

	    	.font2 {
			    font-family:map-get($fonts,'beatrice');
			    font-style:italic;
	    	}

	    	.name {
			    display:inline-block;
			    margin-left:auto;
			    font-family:map-get($fonts,'montreal');
			    font-style:normal;
			    letter-spacing:-0.58px;
			    font-size:29px;
			    text-align:right;
			    float:right;
			    max-width:340px;
			    width:100%;
			    margin-top:26px;
			    padding-right:6px;
			    &:before {
				    position:relative;
				    top:-10px;
				    content:"";
				    display:inline-block;
				    width:240px;
				    height:1px;
				    background:#ffffff;
				    margin-right:14px;
				   	@media all and (max-width:480px){
				   		width:160px;
				   	}
			    }
	    	}

	    	@media all and (max-width:767px){
	    		font-size:40px;

	    		.name {
	    			font-size:20px;
	    		}
	    	}
	    }

	    &__stories {
		    display:-webkit-box;
		    display:-ms-flexbox;
		    display:flex;
		    -webkit-box-pack:end;
		    -ms-flex-pack:end;
		    justify-content:flex-end;
		    margin-bottom:100px;
		    max-width:1008px;
		    width:100%;
		    margin-right:auto;
		    margin-left:auto;
		    background:url(../../../img/home/book-stories.png) no-repeat left top 44%;
		    background-size:72%;

		    @media all and (max-width:767px){
		        -webkit-box-pack:center;
		        -ms-flex-pack:center;
		        justify-content:center;
		        margin-bottom:20px;
		    }

		    &-text {
		    	&-wrp {
				    display:-webkit-box;
				    display:-ms-flexbox;
				    display:flex;
				    -webkit-box-orient:vertical;
				    -webkit-box-direction:normal;
				    -ms-flex-direction:column;
				    flex-direction:column;
				    width:100%;
				    max-width:531px;
				    @media all and (max-width:767px){
				    	max-width:300px;
				    }
		    	}
		    	&-1 {
				    font-family:map-get($fonts,'beatrice');
				    font-size:220.5px;
				    font-weight:600;
				    font-style:italic;
				    line-height:0.9;
				    color:#ffffff;
				    @media all and (max-width:767px){
				    	font-size:130px;
				    }
		    	}
		    	&-2 {
				    font-family:map-get($fonts,'montreal');
				    line-height:0.9;
				    letter-spacing:-8.82px;
				    font-size:220.5px;
				    font-weight:bold;
				    color:#ffffff;
				    position:relative;
				    left:-8px;
				    @media all and (max-width:767px){
				        font-size:130px;
				        left:0;
				    }
		    	}
		    }

		    &-img {
		    	&-wrp {
				    padding:0px 0px 0px 18px;
				    border:2px solid #ffffff;
				    max-width:453px;
				    margin-left:6px;
				    margin-top:-13px;
				    margin-bottom:-6px;
				    @media all and (max-width:767px){
				        margin-top:0;
				        margin-bottom:6px;
				        padding:5px 15px;
				    }

				    img {
					    max-width:399px;
					    width:100%;
				    }
		    	}
		    }

		    &-btn {
		    	&-wrp {
				    display:-webkit-box;
				    display:-ms-flexbox;
				    display:flex;
				    -webkit-box-pack:end;
				    -ms-flex-pack:end;
				    justify-content:flex-end;
				    padding-top:17px;
				    padding-right:70px;
				    @media all and (max-width:767px){
				        padding-right:0;
				        -webkit-box-pack:center;
				        -ms-flex-pack:center;
				        justify-content:center;
				    }

				    .btn-brown-border{
				    	min-width:211px;
				    }
		    	}
		    }
	    }





	}
