
	/**
	*
	* 		BUTTONS
	*			- Add any button styles in here.
	* 			- Below is grouped into a buttons list but the .button is also accessible directly
	*
	**/
	
	.btn-open,
	.closebtn {
		display:none;
	}

	@media screen and (max-width:991px){
		.closebtn {
	        display: block;
	        font-size: 40px;
	        color: #ffffff;
	        padding: 0 15px;
	        height: 48px;
	        text-decoration: none;
	        position: relative;
	        top: -50px;
			&:hover {
		        color: #9a7752;
		        text-decoration: none;
			}
		}
		.btn-open {
			display:block;
		}
	}

	/**
	*
	*	@NOTE - These can be compressed / Fixed.
	* 			- They should be combined into .btn .yellow, etc.
	*
	**/
	.btn {
		&-yellow {
		    min-width: 176px;
		    height: 56px;
		    position: relative;
		    -webkit-transition: 0.3s all;
		    transition: 0.3s all;
		    display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
		    -webkit-box-pack: center;
		    -ms-flex-pack: center;
		    justify-content: center;
		    -webkit-box-align: center;
		    -ms-flex-align: center;
		    align-items: center;
		    font-size: 13px;
		    padding: 22px;
		    border: 0;
		    background: #8d673f;
		    text-decoration: none;
		    line-height: 0.81;
		    letter-spacing: -0.26px;
		    color: #fff;
		    font-weight: bold;
		    border-radius: 6px;
			img {
    			margin-left: 37px;
			}
			&:hover {
			    cursor: pointer;
			    text-decoration: none;
			    background: #724d26;
			}
		}

		&-green,
		&-brown {
		    font-family:map-get($fonts,'demi');
		    text-transform: uppercase;
		    min-width: 178px;
		    height: 64px;
		    position: relative;
		    -webkit-transition: 0.3s all;
		    transition: 0.3s all;
		    display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
		    -webkit-box-pack: center;
		    -ms-flex-pack: center;
		    justify-content: center;
		    -webkit-box-align: center;
		    -ms-flex-align: center;
		    align-items: center;
		    font-size: 10px;
		    padding: 22px;
		    padding-top: 23px;
		    border: 0;
		    background: #8d673f;
		    text-decoration: none;
		    line-height: 0.81;
		    letter-spacing: 1px;
		    color: #ffffff;
		    font-weight: bold;
		    border-radius: 6px;
			img {
			    margin-left: 37px;
			}
			&:hover {
			    cursor: pointer;
			    text-decoration: none;
			    background: #724d26;
			}
		}

		&-green {
			background:#11f0bd;
			color:#000;
			&:hover {
				background:darken(#11f0bd,20%);
			}
		}

		&-brown-border,
		&-brown-border-grayed {
		    font-family:map-get($fonts,'demi');
		    text-transform: uppercase;
		    min-width: 176px;
		    height: 66px;
		    position: relative;
		    -webkit-transition: 0.3s all;
		    transition: 0.3s all;
		    display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
		    -webkit-box-pack: center;
		    -ms-flex-pack: center;
		    justify-content: center;
		    -webkit-box-align: center;
		    -ms-flex-align: center;
		    align-items: center;
		    font-size: 11px;
		    padding: 22px;
		    border: 1px solid #8d673f;
		    background: transparent;
		    text-decoration: none;
		    line-height: 0.81;
		    letter-spacing: 1.1px;
		    color: #fff;
		    font-weight: bold;
		    border-radius: 6px;

		    img {
		    	margin-left:32px;
		    }
		    
		    &:hover {
			    background: #8d673f;
			    cursor: pointer;
			    text-decoration: none;
		    }

		}

	    &-brown-border-grayed {
	    	font-size:11px;
    	    border: 1px solid #2a2a2a;
		    border-radius: 6px;
		    background-color: #2a2a2a;
		    cursor: default;
		    white-space:nowrap;
			&:hover {
			    cursor: pointer;
			    text-decoration: none;
			    background: #2a2a2a;
			}
	    }

		&-white-border {
		    font-family:map-get($fonts,'demi');
		    text-transform: uppercase;
		    min-width: 176px;
		    height: 60px;
		    position: relative;
		    -webkit-transition: 0.3s all;
		    transition: 0.3s all;
		    display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
		    -webkit-box-pack: center;
		    -ms-flex-pack: center;
		    justify-content: center;
		    -webkit-box-align: center;
		    -ms-flex-align: center;
		    align-items: center;
		    font-size: 11px;
		    padding: 22px;
		    padding-left: 30px;
		    padding-right: 26px;
		    border: 1px solid #ffffff;
		    background: transparent;
		    text-decoration: none;
		    line-height: 0.95;
		    letter-spacing: 1.1px;
		    color: #fff;
		    font-weight: bold;
		    border-radius: 6px;
		    .plus {
		    	font-family:map-get($fonts,'main');
			    margin-left: 37px;
			    font-size: 28.5px;
			    font-weight: normal;
		    }
		    &:hover {
			    cursor: pointer;
			    text-decoration: none;
			    background: #8d673f;
			    border: 1px solid #8d673f;
			    cursor: pointer;
			    text-decoration: none;
		    }
		}
	}
