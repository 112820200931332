
	/**
	*
	* 		Layout
	*			- Grid and Layout styles for the website
	*
	**/

	
	*,
	:after,
	:before {
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	}


	:active,
	:focus,
	:hover {
	    outline: 0 !important;
	    outline-offset: 0 !important;
	}


	.container-np {
	    max-width: 1230px;
	    width: 100%;
	    padding-left: 15px;
	    padding-right: 15px;
	    margin: 0 auto;
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	}