
	/**
	*
	* 		SIDENAV
	*			- The side navigation settings will go here
	*
	**/

	.sidenav {
	    position: relative;
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    
	    &__ul {
		    display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
		    -webkit-box-align: center;
		    -ms-flex-align: center;
		    align-items: center;
		    height: 36px;
		    position: relative;
		    top: 1px;
		    padding-left:30px;

		    li {
			    list-style-type: none;
			    margin-left: 63px;

			    a {
				    font-family: map-get($fonts,'demi');
				    text-transform: uppercase;
				    -webkit-transition: 0.3s all;
				    transition: 0.3s all;
				    font-size: 10px;
				    line-height: 1.5;
				    font-weight: bold;
				    color: #ffffff;
				    letter-spacing: 1px;
				    text-decoration: none;

				    &:hover {
    					color:#8d673f;
				    }
			    }

		    }

		    &-btn {
			    display: -webkit-box;
			    display: -ms-flexbox;
			    display: flex;
			    -webkit-box-align: center;
			    -ms-flex-align: center;
			    align-items: center;
			    height: 36px;
			    margin-left: 44px;
			    
			    li {
				    list-style-type: none;
				    margin-left: 24px;

				    @media screen and (max-width: 991px){
				    	margin-left:0;
				    }

			    }

		    }

	    }



	    @media screen and (max-width: 991px){
	        display: block;
	        height: 100%;
	        width: 0;
	        min-width: auto;
	        position: fixed;
	        z-index: 99999;
	        top: 0;
	        right: 0;
	        background-color: #000000;
	        overflow-x: hidden;
	        -webkit-transition: 0.5s;
	        transition: 0.5s;
	        padding-top: 60px;
	        -webkit-box-shadow: 2px 0 6px rgba(51, 51, 51, 0.26);
	        box-shadow: 2px 0 6px rgba(51, 51, 51, 0.26);
	        padding-bottom: 20px;
	        
	    	&__ul {
		        width: 280px;
		        -webkit-box-orient: vertical;
		        -webkit-box-direction: normal;
		        -ms-flex-direction: column;
		        flex-direction: column;
		        height: auto;

		        top: 0;
		        li {
			        width: 100%;
			        text-align: left;
			        list-style-type: none;
			        margin-left: 0;

		        	a {
				        display: -webkit-box;
				        display: -ms-flexbox;
				        display: flex;
				        -webkit-box-align: center;
				        -ms-flex-align: center;
				        align-items: center;
				        padding: 0 15px;
				        height: 48px;
				        font-size: 18px;
		        	}
		        }

		        &-btn {
			        width: 280px;
			        -webkit-box-orient: vertical;
			        -webkit-box-direction: normal;
			        -ms-flex-direction: column;
			        flex-direction: column;
			        margin-top: 20px;
			        height: auto;
			        margin-left: 0;
		        }
			}
	    }
	}
