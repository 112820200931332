
	/**
	*
	* 		Roadmap
	*			- The roadmap component goes here
	*
	**/

	.roadmap {
	    margin-top: 49px;
	    padding-bottom: 45px;

		@media screen and (max-width:1199px){
			overflow:hidden;
		}

		@media screen and (max-width:991px){
	        margin-top: 20px;
	        padding-bottom: 0;
	        margin-bottom: 0;
		}

		.header-home__title-2 {
			margin-bottom:32px;
		}

		&__title {
			font-family:map-get($fonts,'montreal');
		    font-size:50px;
		    font-weight:bold;
		    margin-bottom:44px;
		    color:#ffffff;
		    line-height:1.35;
		    letter-spacing:-1px;
		    span {
		    	font-family:map-get($fonts,'beatrice');
		    	font-style:italic;
		    }
		    @media all and (max-width:767px){
		    	font-size:36px;
		    }
		}

		&__wrp {
		    width: 100%;
		    max-width: 862px;
		    margin-right: auto;
		    margin-left: auto;
		    display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
		    -webkit-box-orient: vertical;
		    -webkit-box-direction: normal;
		    -ms-flex-direction: column;
		    flex-direction: column;

		    &-img {
			    display: -webkit-box;
			    display: -ms-flexbox;
			    display: flex;
			    -webkit-box-align: center;
			    -ms-flex-align: center;
			    align-items: center;
			    -webkit-box-pack: center;
			    -ms-flex-pack: center;
			    justify-content: center;
			    height: 275px;
			    border-top-left-radius: 6px;
			    border-bottom-left-radius: 6px;
			    background: #1e1810;
			    width: 100%;
/*
			    img {
			        max-width: calc(100% - 30px) !important;
			        top: 0 !important;
			        left: 0 !important;
			        max-height: 240px;
			    }
*/
		    }

		    &-img_1 img {
			    position: relative;
			    left: 11px;
			    top: -11px;
		    }

		    &-img_2 img {
			    position: relative;
			    left: -1px;
			    top: 14px;
		    }

		    &-img_3 img {
			    position: relative;
			    left: 5px;
			    top: -6px;
		    }

		    &-img_4 img {
			    position: relative;
			    left: 11px;
			    top: -6px;
		    }

		    @media all and (max-width:800px) and (min-width:480px){
		    	img {
		    		max-width:90% !important;
		    	}
		    	&-img_4 img {
		    		max-width:100px !important;
		    	}
		    }
		}

		&__phase {
		    display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
		    width: 100%;
		    border-bottom: 1px solid #452e16;
		    padding-top: 49px;
		    padding-bottom: 49px;
		    position: relative;

		    @media screen and (max-width:767px){
		        padding-top: 25px;
		        padding-bottom: 25px;
		    }

		    @media screen and (max-width:480px){
		        padding-top: 20px;
		        padding-bottom: 0;
		        -webkit-box-orient: vertical;
		        -webkit-box-direction: normal;
		        -ms-flex-direction: column;
		        flex-direction: column;
		    }

		    &:after {
			    content: "";
			    position: absolute;
			    bottom: 0;
			    margin-left: calc(37% - 1px);
			    display: block;
			    width: 1px;
			    height: 120px;
			    background: #452e16;
			    z-index: 1;

			    @media screen and (max-width:1199px){
        			margin-left: calc(30% - 1px);
			    }

			    @media screen and (max-width:480px){
			        margin-left: calc(100% - 1px);
			    }
		    }

		    &:before {
			    content:"";
			    position: absolute;
			    top: 0;
			    margin-left: calc(37% - 1px);
			    display: block;
			    width: 1px;
			    height: 120px;
			    background: #452e16;
			    z-index: 1;

			    @media screen and (max-width:1199px){
        			margin-left: calc(30% - 1px);
			    }

			    @media screen and (max-width:480px){
			        margin-left: calc(100% - 1px);
			    }

		    }

		    &.icon {
		    	padding-top:14px;

		    	.roadmap__phase-img-wrp {
		    		position:relative;
		    	
		    		&:before {
					    content:"";
					    display:block;
					    position:absolute;
					    width:22px;
					    height:22px;
					    background:#ffffff;
					    top:510px;
					    right:-11px;
					    border-radius:50%;
					    z-index:10;
					    -webkit-box-shadow:0px 0px 0px 15px rgba(255,255,255,1);
					    box-shadow:0px 0px 0px 15px rgba(255,255,255,1);
					    animation: pulse 2s infinite;
					    @media all and (max-width:480px){
					    	top:920px;
					    }
		    		}

		    		&:after {
					    content:"";
					    position:absolute;
					    z-index:9;
					    top:-19px;
					    right:-4px;
					    display:block;
					    width:8px;
					    height:532px;
					    background:#8d673f;
					    @media all and (max-width:480px){
					    	height:950px;
					    }
		    		}
		    	}

		    }

		    &-img-wrp {
			    border-right: 1px solid #452e16;
			    width: 37%;

			    @media screen and (max-width:1199px){
			    	width:30%;
			    }

			    @media screen and (max-width:767px){
			        display: -webkit-box;
			        display: -ms-flexbox;
			        display: flex;
			        -webkit-box-align: center;
			        -ms-flex-align: center;
			        align-items: center;
			    }

			    @media screen and (max-width:480px){
			    	width:100%;
			    	padding:0;
			    }

			    img {
			    	position:relative;
			    }

		    }

		    &-texts {
			    height:275px;
			    padding-top:0;
			    padding-left:53px;
			    padding-right:5px;
			    width:63%;
			    display:-webkit-box;
			    display:-ms-flexbox;
			    display:flex;
			    -webkit-box-orient:vertical;
			    -webkit-box-direction:normal;
			    -ms-flex-direction:column;
			    flex-direction:column;
			    -webkit-box-pack:center;
			    -ms-flex-pack:center;
			    justify-content:center;

			    @media screen and (max-width:1199px){
			        padding: 20px 30px;
			        width: 70%;
			    }

			    @media screen and (max-width:480px){
			        width: 100%;
			        border-right: 1px solid #452e16;
			        padding: 30px 10px;
			        height: auto;
			    }
		    }

		    &-title {
			    font-family:map-get($fonts,'montreal');
			    margin-bottom:15px;
			    font-size:23px;
			    font-weight:bold;
			    line-height:1.89;
			    letter-spacing:-0.46px;
			    color:#8d673f;

			    @media screen and (max-width:767px){
			        margin-bottom:10px;
			        font-size:22px;
			        line-height:1.1;
			    }
		    }

		    &-top {
			    font-family:map-get($fonts,'montreal');
			    text-transform:uppercase;
			    margin-bottom:0;
			    font-size:11px;
			    font-weight:bold;
			    line-height:1.5;
			    letter-spacing:1.1px;
			    text-align:left;
			    color:#444444;
		    }

		    
		    &-text {
			    max-width: 476px;
			    font-size: 16px;
			    font-weight: normal;
			    line-height: 1.5;
			    color: #fff;
		    }
		}

	}
