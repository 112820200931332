
	/**
	*
	* 		Persones
	*			- The persones component goes here
	*
	**/

	.persones {
	    margin-top: 21px;
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	    width: calc(100% + 39px);

		@media screen and (max-width:950px){
			width:calc(100% + 10px);
		}

		&__option {
		    margin-right: 39px;
		    margin-bottom: 35px;

		    img {
		    	max-width:260px;
		    }

		    @media screen and (max-width:950px){
		        max-width: 260px;
		        width: calc(33.333% - 10px);
		        margin-right: 10px;
		        margin-bottom: 20px;
				
				img {
			        width: 100%;
			        height: auto;
				}
			}

			@media screen and (max-width:526px){
				width:calc(50% - 10px);
			}
		}

		&__twitter {
		    margin-bottom:12px;
		    display:-webkit-box;
		    display:-ms-flexbox;
		    display:flex;
		    -webkit-box-pack:justify;
		    -ms-flex-pack:justify;
		    justify-content:space-between;
		    padding-right:7px;
		    text-decoration:none;

		    @media screen and (max-width:767px){
		    	padding-right:5px;
		    }

		    .nickname {
			    font-family: map-get($fonts,'demi');
			    font-size:12px;
			    font-weight:bold;
			    color:#8d673f;
			    text-transform:uppercase;
			    line-height:1.5;
			    letter-spacing:0.24px;
		    }

		    img {
		    	width:18px;
		    }

		}

		&__text {
		    max-width:260px;
		    width:100%;
		    margin-top:8px;
		    font-size:15px;
		    color:#fff;
		    line-height:1.6;
		    @media all and (max-width:480px){
		        font-size:14px;
		        line-height:1.2;
		    }
		    @media all and (max-width:360px){
		    	font-size:12px;
		    }
		}
	}
