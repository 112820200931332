
	/**
	*
	* 		Modal
	*			- The modal component goes here
	*
	**/

	.faq {
		padding-top:217px;

		@media screen and (max-width:767px){
			padding-top:80px;
		}

		&__wrp {
		    max-width: 863px;
		    width: 100%;
		    margin-right: auto;
		    margin-left: auto;
		}

		&_two {
		    padding-top:15px;
		    margin-top:-38px;
			@media screen and (max-width:767px){
				margin-top:50px;
			}
			@media screen and (max-width:480px){
				margin-top:0;
			}

			.faq__accordion {
				border-top:1px solid #242424;

				&-wrp {
					margin-bottom:100px;
				}

				&:last-child {
					border-bottom:1px solid #242424;
				}
			}
		}

		&__title {
		    font-family:map-get($fonts,'maragsa');
		    font-size: 80px;
		    line-height: 1.2;
		    color: #ffffff;
		    letter-spacing: 1.2px;

		    @media screen and (max-width:767px){
		    	font-size:60px;
		    }
		}

		&__accordion {
		    padding-top: 26px;
		    padding-bottom: 28px;
		    min-width: 100%;
		    border-top: 1px solid #8d673f;

			&-wrp {
			    display: -webkit-box;
			    display: -ms-flexbox;
			    display: flex;
			    -webkit-box-orient: vertical;
			    -webkit-box-direction: normal;
			    -ms-flex-direction: column;
			    flex-direction: column;
			    min-width: 100%;
			    margin-top: 51px;
			    margin-bottom: 125px;

			    @media screen and (max-width: 767px) {
			    	margin-bottom:80px;
			    }

			}

			&:last-child {
    			border-bottom: 1px solid #8d673f;
			}

			&.open {
				.faq__accordion-btn {
					.plus {
						display:none;
					}
					.minus {
						display:block;
					}
				}

				.faq__accordion-content {
					max-height:300px;

					@media screen and (max-width:767px) {
						max-height:600px;
					}
				}
			}

			&-btn {
			    -webkit-user-select: none;
			    -moz-user-select: none;
			    -ms-user-select: none;
			    user-select: none;
			    min-width: 100%;
			    min-height: 22px;
			    display: -webkit-box;
			    display: -ms-flexbox;
			    display: flex;
			    -webkit-box-align: center;
			    -ms-flex-align: center;
			    align-items: center;
			    -webkit-box-pack: justify;
			    -ms-flex-pack: justify;
			    justify-content: space-between;

			    &:hover {
			    	cursor:pointer;
			    }

			    p {
				    max-width: 730px;
				    -webkit-transition: 0.3s all;
				    transition: 0.3s all;
				    font-family:map-get($fonts,'maragsa');
				    line-height: 1.25;
				    letter-spacing: 0.46px;
				    font-size: 23px;
				    color: #ffffff;
				    padding-right: 10px;
			    }

			    .minus,
			    .plus {
				    font-size: 29px;
				    color: #ffffff;
				    padding-right: 10px;

				    &.minus {
				    	display:none;
				    }
			    }

			}

			&-content {
			    max-height: 0;
			    overflow: hidden;
			    -webkit-transition: 1s all;
			    transition: 1s all;

			    p {
				    margin-top: 32px;
				    margin-bottom: 24px;
				    font-size: 16px;
				    line-height: 1.88;
				    color: #ffffff;
			    }
			}
		}
	}
