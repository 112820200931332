
	/**
	*
	* 		TABS
	*			- The tabs component goes here
	*
	**/

	.team {
	    padding-top:76px;
	    padding-bottom:90px;

	    @media screen and (max-width:991px){
	        margin-top: 20px;
	        padding-bottom: 60px;
	    }

	    .roadmap {
	    	&__title {
	    		margin-bottom:27px;
	    	}
	    }

	    .header-home {
	    	&__title {
	    		&-2 {
				    font-size: 20px;
				    letter-spacing: 0.4px;
	    		}
	    	}
	    }

	    &__wrp {
		    width:100%;
		    max-width:862px;
		    margin-right:auto;
		    margin-left:auto;
	    }
	}
