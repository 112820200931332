
	/**
	*
	* 		Home
	*			- Styles specific to the home page should go here
	*
	**/


	html {
		&.scrolled {
			#pbuild {
				.navigation {
					background:#000;
					&__container {
						.navigation__logo-link {
							font-size:17px;
						}
						> .social {
							a,
							span {
								font-size:14px;
								span {
									width:185px;
								}
							}
							span {
								img {
									width:30px;
								}
								span {
									width:auto;
									font-size:20px;
								}
							}
						}
					}
				}
			}
		}

		.ReactModalPortal {
			z-index:2;
			position:relative;
		}
		#pbuild {

			background:#181818;
			min-width:320px;


			#preloader {
				width:100%;
				height:100%;
				background:#6366f1;
				position:fixed;
				display:flex;
				z-index:9;
				&[data-preloaded="true"] {
					display:none;
				}
				svg {
					max-width:80%;
					margin:auto;
				}
			}

			.navigation {
				height:auto;
				position:absolute;
				top:0;
				z-index:2;

				.hello-bar {
					background:#6366f1;
					color:#FFF;
					text-align:center;
					font-size:17px;
					font-weight:500;
					padding:10px;
					text-align:center;
					p {
						width:100%;
						font-family:map-get($fonts,'aventa');
						a {
							cursor:pointer;
							font-weight:100;
							font-weight:bold;
							text-decoration:underline;
							color:#FFF;
							&:hover {
								//color:darken(#FFF,10%);
							}
						}
						@media all and (max-width:500px){
							span {
								display:none;
							}
						}
					}
				}

				.btn-open {
					display:none !important;
				}

				&__container {
					padding-top:0;
					padding-bottom:0;

					.navigation__logo-link {
						font-size:20px;
						transition:0.3s ease font-size;
					}


					@media all and (max-width:500px){
						.navigation__logo {
							padding:30px 0;
							margin:0 auto;
						}
					}

					&.approved {
						.navigation__logo {
							padding:30px 0;
						}
					}

					> .social {
						@media all and (max-width:500px){
							display:none;
						}
						> a,
						> span {
							color:#FFF;
							font-family:map-get($fonts,'aventa');
							transition:0.5s ease color;
							display:flex;
							cursor:pointer;
							padding:20px;
							font-size:16px;
							transition:0.3s ease font-size;

							span {
								margin:auto;
								text-transform:uppercase;
								text-align:right;
								font-weight:700;
								width:205px;
								transition:0.3s ease width;
								@media all and (max-width:400px){
									width:auto;
								}
							}
							img {
								max-width:35px;
								display:inline-block;
								filter:invert(49%) sepia(94%) saturate(3733%) hue-rotate(222deg) brightness(94%) contrast(102%);
								transition:0.2s ease transform;
								@media all and (max-width:1000px){
									max-width:40px;
								}
							}


							&:hover {
								img {
									filter:invert(1);
									transform:rotate(10deg);
								}
							}
						}

						> span {
							img {
								width:35px;
								margin-right:10px;
								transition:0.3s ease width;
							}
							span {
								width:auto;
								font-size:25px;
								color:#6366f1;
								transition:0.3s ease font-size;
							}
						}
					}
				}
			}

			.container {
				max-width:1000px;
				margin:0 auto;
				padding:0 20px;
			}


			.header {
				background:#181818;
				position: relative;
			    padding-bottom: 55%; /* 16:9 */
			    padding-top: 25px;
			    height: 0;
			    top:27px;
				z-index:1;
				max-width:100%;
				overflow:hidden;

				@media all and (max-width:600px){
					min-height:calc( 90vh - 27px );
				}

				div {
					position:absolute;
					top:0;
					left:0;
					width:100%;
					height:100%;
					display:flex;
					h1 {
						font-size:100px;
						font-family:map-get($fonts,'aventa');
						color:#FFF;
						margin:auto auto 10% auto;
						letter-spacing:-0.025em;
						font-weight:900;
						text-align:center;
						display:flex;

						&:after {
							content:'Meebs';
							padding-left:10px;
							opacity:0;
						}

						@media all and (max-width:900px){
							font-size:70px;
						}

						@media all and (max-width:600px){
							font-size:40px;
						}
						span {
							position:relative;
							font-family:map-get($fonts,'ppbit');
							color:#7e81fb;
							font-size:140%;
							em {
								opacity:0;
								font-style:normal;
								position:absolute;
								line-height:82%;
								top:0;
								padding-left:20px;
								animation:4s infinite build;
							}
						}
					}
				}
				iframe {
					pointer-events:none;
				    position: absolute;
				    top: 0;
				    left: 0;
				    width: 100%;
				    height: 100%;
				    @media all and (max-width:600px){
				    	transform:scale(3.5);
				    }
				}
			}

			.steps {
				padding:80px 0;
				z-index:1;
				position:relative;
				background:#181818;

				.container {
					border:1px solid #444444;

					h2 {
						font-size:82px;
						color:#FFF;
						font-family:map-get($fonts,'aventa');
						font-weight:900;
						text-align:center;
						padding-top:50px;
						padding-bottom:100px;
						letter-spacing:-2px;
						@media all and (max-width:550px){
							font-size:40px;
						}
					}

					ol {
						margin:0;
						padding-bottom:100px;
						li {
							display:flex;
							margin:0 auto 50px;

							&.active {
								.image {
									> img {
										filter:invert(49%) sepia(94%) saturate(3733%) hue-rotate(222deg) brightness(94%) contrast(102%);
									}
								}
								p {
									color:#FFF;
									a {
										color:#FFF;
									}
								}
							}
							.image {
								width:30%;
								> img {
									max-width:100px;
									float:right;
									filter:invert(29%) sepia(0%) saturate(1906%) hue-rotate(190deg) brightness(87%) contrast(93%);
									@media all and (max-width:550px){
										max-width:100px;
									}
								}
							}
							p {
								font-family:map-get($fonts,'aventa');
								color:#444;
								font-size:20px;
								padding-left:30px;
								margin:auto 0;
								width:65%;
								font-weight:500;
								img {
									max-width:35px;
									padding-left:10px;
									display:inline-block;
									filter:invert(1);
								}
								a {
									color:#444;
									font-size:23px;
									text-decoration:underline;
									cursor:pointer;
									font-weight:900;
								}
								@media all and (max-width:550px){
									font-size:18px;
									a {
										font-size:18px;
									}
								}
							}
						}
					}
				}
			}

			.projects {
				background:#FFF;
				.container {
					position:relative;
					z-index:1;
					padding-top:100px;
					background:#FFF;
					h2 {
						font-size:35px;
						color:#333;
						letter-spacing:-0.01em;
						font-family:map-get($fonts,'aventa');
						font-weight:400;
						text-align:center;
						padding-bottom:50px;
					}

					.filters {
						display:flex;
						gap:20px;
						padding-bottom:50px;
						@media all and (max-width:750px){
							flex-wrap:wrap;
							justify-content:space-between;
						}
						li {
							border:1px solid #6366f1;
							color:#6366f1;
							font-family:map-get($fonts,'aventa');
							font-size:17px;
							padding:20px 30px;
							border-radius:5px;
							margin:auto;
							width:100%;
							text-align:center;
							cursor:pointer;
							@media all and (max-width:750px){
								max-width:calc(50% - 10px);
								margin:0;
							}

							&.selected,
							&:hover {
								background:#6366f1;
								color:#FFF;
							}
						}
					}
				}


				.wrap {

					[data-desktop] {
						display:block;
					}
					[data-mobile] {
						display:none;
					}

					@media all and (max-width:600px){
						&[data-state="false"] {
							position:relative;
							max-height:600px;
							overflow:hidden;

							[data-desktop] {
								display:none;
							}
							[data-mobile] {
								display:block;
							}
						}
					}

					> div {
						position:relative;

						.list {
							max-width:1500px;
							padding:50px 20px;
							margin:0 auto;
							display:flex;
							flex-wrap:wrap;
							gap:20px;
							//justify-content:space-between;

							.project {
								max-width:calc(20% - 20px);

								@media all and (max-width:1300px){
									max-width:calc(25% - 20px);
								}

								@media all and (max-width:1000px){
									max-width:calc(33% - 20px);
								}

								@media all and (max-width:750px){
									max-width:calc(50% - 20px);
								}

								@media all and (max-width:500px){
									max-width:calc(100% - 20px);
									margin:0 auto;
								}

								.image {
									border-radius:5px;
									img {
										width:100%;
										border-radius:5px;
									}
								}

								.details {
									font-size:18px;
									font-weight:500;
									margin:10px 0 15px;
									align-items:center;
									display:grid;
									grid-template-columns:1fr 1fr 1fr;
									.tag {
										font-family:map-get($fonts,'aventa');
										background:#e2e2e2;
										border-radius:5px;
										text-transform:uppercase;
										color:#181818;
										margin-right:15px;
										font-weight:600;
										font-size:12px;
										letter-spacing:0.05em;
										display:inline-block;
										padding:5px 10px 2px;
										vertical-align:middle;
									}
									.owner {
										display:block;
										text-align:center;
										white-space:nowrap;
									}
								}

								.button {
									width:100%;
									padding:20px 0;
									font-weight:100;
									font-size:17px;
									border-radius:5px;
									background:#6366f1;
									display:block;
									color:#FFF;
									text-align:center;
									transition:0.3s ease background;
									cursor:pointer;
									&:not(span):hover {
										background:#131313;
									}
								}

								&[data-state="unavailable"] {
									filter:grayscale(1);
									.button {
										cursor:not-allowed;
									}
								}
							}

						}

						.activate {
							position:absolute;
							top:0;
							left:0;
							width:100%;
							height:100%;
							> div {
								background:rgba(0,0,0,.85);
								width:100%;
								height:100%;
								overflow:hidden;
							}
							@media all and (max-width:600px){
								background:url('../../../img/meebits/bg.jpg') center center no-repeat;
								background-size:cover;
								max-height:600px;
								> div {
									background:rgba(0,0,0,.65);
								}
							}
						}

					}

					.restricted {
						width:100%;
						position:absolute;
						top:0;
						z-index:0;
						&.notSticky {
							transform:none;
						}
						.start {
						 	margin:0 auto;
						 	padding:20px;
						 	margin:30px auto 0;
							text-align:center;
							display:flex;
							flex-direction:column;

							h3 {
								font-size:70px;
								color:#FFF;
								font-family:map-get($fonts,'aventa');
								font-weight:900;
								margin:20px 0;
							}

							p {
								font-family:map-get($fonts,'aventa');
								font-size:14px;
								padding-top:10px;
								margin-bottom:40px;
								line-height:18px;
								color:#FFF;
							}

							.button {
								width:100%;
								padding:30px 0;
								font-weight:100;
								font-size:13px;
								border-radius:10px;
								background:#6366f1;
								display:flex;
								margin:0 auto;
								color:#FFF;
								text-align:center;
								transition:0.3s ease background;
								cursor:pointer;
								margin-top:20px;
								max-width:225px;
								text-transform:uppercase;
								text-align:center;
								align-items:center;
								justify-content:center;
								font-weight:500;
								img {
									width:35px;
									filter:invert(1);
									margin:auto 10px auto 0;
								}
								&:hover {
									background:#131313;
								}
							}
						}
					}
				}
			}





			.more {
				padding:100px 0 150px;
				background:#6366f1;
				z-index:1;
				position:relative;
				header {
					text-align:center;
					padding-bottom:60px;
					h2 {
						font-size:80px;
						color:#FFF;
						font-family:map-get($fonts,'aventa');
						font-weight:800;
						+ p {
							font-weight:500;
							font-size:18px;
							color:#FFF;
						}
						@media all and (max-width:550px){
							font-size:40px;
							padding-bottom:20px;
						}
					}
				}

				.guide {
					position:relative;

					img {
						width:100%;
					}
					.button {
						background:#000;
						position:absolute;
						display:inline-block;
						max-width:175px;
						margin:0 auto;
						width:100%;
						padding:20px 0;
						font-weight:100;
						font-size:18px;
						border-radius:5px;
						display:block;
						color:#FFF;
						text-align:center;
						transition:0.3s ease background;
						cursor:pointer;
						left:calc( 50% - ( 175px / 2 ) );
						bottom:20%;
						&:hover {
							background:#6366f1;
						}
						@media all and (max-width:550px){
							position:relative;
							margin:50px auto 0;
							left:auto;
							bottom:auto;
						}
					}
				}

			}


			.questions {
				padding:125px 0;
				position:relative;
				z-index:1;
				background:#181818;
				header {
					text-align:center;
					padding-bottom:50px;
					h2 {
						font-size:80px;
						color:#FFF;
						font-family:map-get($fonts,'aventa');
						font-weight:800;
						@media all and (max-width:550px){
							font-size:40px;
						}
					}
				}

				dl {
					dt {
						border-top:1px solid #4d4d4d;
						font-size:23px;
						color:#FFF;
						font-weight:600;
						font-family:map-get($fonts,'aventa');
						padding:40px 40px 40px 0;
						position:relative;
						cursor:pointer;

						&:first-child {
							border-top:none;
						}

						&:after {
							content:'+';
							position:absolute;
							font-weight:700;
							font-size:54px;
							right:0;
							top:25px;
						}

						&.opened {
							&:after {
								content:'-';
							}
							+ dd {
								max-height:150px;
							}
						}
					}
					dd {
						font-size:20px;
						color:#AAA;
						font-weight:600;
						font-family:map-get($fonts,'aventa');
						margin:0 0 40px;
						max-height:0;
						overflow:hidden;
						transition:0.5s ease max-height;
						a {
							color:#AAA;
							font-size:20px;
							text-decoration:underline;
						}

					}
				}
			}


			footer {
				background:#000;
				padding:50px 0;
				position:relative;
				z-index:1;
				.container {
					max-width:1500px;
					position:relative;
					.copy {
						position:absolute;
						font-size:13px;
						color:#FFF;
						font-family:map-get($fonts,'aventa');
						top:50%;
						left:20px;
					}
					.social {
						margin:0 auto;
						display:flex;
						gap:75px;
						justify-content:center;
						align-items:center;
						a {
							img {
								filter:invert(49%) sepia(94%) saturate(3733%) hue-rotate(222deg) brightness(94%) contrast(102%);
								width:50px;
							}
							&:hover {
								img {
									filter:invert(1);
								}
							}
						}
					}
					@media all and (max-width:900px){
						display:flex;
						flex-direction:column-reverse;
						align-items:center;
						justify-content:center;
						.copy {
							position:static;
							padding-top:20px;
						}
					}
				}
			}

			.ReactModalPortal {
				.ReactModal__Overlay--after-open {
					background:rgba(0,0,0,.7) !important;
					.ReactModal__Content {
						background:#000 !important;
						border:1px solid lighten(#131313,10%) !important;
						position:relative;
						border-radius:2px solid #6366f1 !important;
						overflow:visible !important;
						inset:5% 10% !important;
						display:flex;

						.close {
							position:absolute;
							font-weight:700;
							font-size:30px;
							right:-10px;
							top:-10px;
							background:#6366f1;
							width:40px;
							height:40px;
							border-radius:100%;
							text-align:center;
							color:#FFF;
							font-family:map-get($fonts,'aventa');
							font-weight:900;
							line-height:40px;
							cursor:pointer;
							transition:0.2s ease all;
							&:hover {
								background:#FFF;
								color:#000;
							}
						}


						.tweet {
							overflow-y:scroll;
							padding:40px 20px;
							margin:auto;
							@media all and (max-width:400px){
								padding:20px 0;
							}
							header {
								display:flex;

								.tweet-steps {
									padding-left:0;
									display:flex;
									margin:auto;
									li {
										font-size:20px;
										color:#FFF;
										border-radius:100%;
										width:50px;
										height:50px;
										line-height:50px;
										text-align:center;
										border:1px solid #FFF;
										margin:0 20px;
										@media all and (max-width:450px){
											margin:0 10px;
										}
										&.current {
											background:#6366f1;
											border:1px solid #6366f1;
										}
									}
								}

							}

							ol {
								padding-left:10px;
								li {
									margin:30px 0;
									overflow:hidden;

									&.hide {
										display:none;
									}

									h2.head {
										font-family:map-get($fonts,'aventa');
										color:#FFF;
										font-size:35px;
										font-weight:900;
										text-align:center;
										padding-top:30px;
										span {
											font-weight:900;
											padding-right:20px;
											display:block;
											color:#6366f1;
											font-size:13px;
											text-transform:uppercase;
											font-weight:700;
										}
									}

									p.note {
										font-family:map-get($fonts,'aventa');
										color:#FFF;
										font-size:14px;
										padding:30px 0 15px;
										text-align:center;
									}

									p.error {
										font-family:map-get($fonts,'aventa');
										color:#F00;
										font-size:14px;
										padding:30px 0 15px;
										text-align:center;
									}
									.input {
										display:flex;
										border-radius:5px;
										margin:60px auto 30px;
										background:#131313;
										border:1px solid #6366f1;
										input {
											display:inline-block;
											font-size:25px;
											padding:15px 30px 15px 0;
											font-family:map-get($fonts,'aventa');
											color:#FFF;
											background:none;
											border:none;
											&::placeholder {
												color:#FFF;
											}
										}
										span {
											padding:15px;
											font-size:25px;
											color:#FFF;
										}
									}
									.buttons {
										text-align:center;
										padding-top:30px;
										.button {
											position:relative;
											width:auto;
											padding:20px 30px;
											font-weight:100;
											font-size:17px;
											border-radius:5px;
											background:#6366f1;
											display:inline-block;
											color:#FFF;
											text-align:center;
											cursor:pointer;
											margin-top:20px;
											margin:0 auto;
											&[disabled] {
												background:#AAA;
												cursor:default;
											}
											img {
												width:20px;
												filter:invert(1);
											}
											svg {
												position:absolute;
												top:-99px;
												left:-90px;
												+ span {
													padding-left:20px;
												}
											}
											&[data-state="true"] {
												background:#999;
												cursor:not-allowed;
												&:hover {
													color:#FFF;
													background:#999;
												}
											}
											&:hover {
												color:#000;
												background:#FFF;
												img {
													filter:none;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}

		}
	}